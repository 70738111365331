<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="판심 법무법인" tabActive="판심 법무법인 소개">
                <template #titHead>T</template>
                <template #tit>he Firm</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--about page-section--first page-section--last">
            <v-row>
                <v-col cols="12" md="5">
                    <u-tit-default data-aos="fade-up" class="line-height-15">
                        판심의 성범죄사건 대응 전략. <br />
                        따라할 수도, 비교할 수도 없습니다.
                    </u-tit-default>
                    <u-txt-default data-aos="fade-up" data-aos-delay="200" class="txt--xxl line-height-15 mt-12px mt-md-24px">
                        판사의 마음, 판심만의 <br />
                        원하는 판결을 받기 위한 고도의 소송전략은?
                    </u-txt-default>
                </v-col>
                <v-col cols="12" md="7" class="mt-20px mt-md-0">
                    <div class="pl-2px">
                        <v-row>
                            <v-col data-aos="fade-up" data-aos-delay="200" v-for="(item, index) in info" :key="index" cols="12">
                                <card-secondary>
                                    <div class="pa-16px pa-md-24px">
                                        <v-row align="center" justify="center">
                                            <v-col cols="12" md="auto">
                                                <v-img :src="item.icon" :width="$vuetify.breakpoint.mdAndUp ? 120 : 100" :aspect-ratio="1 / 1" />
                                            </v-col>
                                            <v-col cols="12" md="">
                                                <u-tit-default class="tit--sm font-primary font-weight-medium">
                                                    {{item.title}}
                                                </u-tit-default>
                                                <u-txt-default class="line-height-15 mt-10px">
                                                    {{item.txt}}
                                                </u-txt-default>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </card-secondary>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <template #pageSectionFoot>
                <v-img data-aos="zoom-out" src="/images/sub/firm/about/about-bg.svg" :aspect-ratio="704 / 486" class="page-section--about__bg" />
            </template>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
    },
    data() {
        return {
            info: [
                {
                    title: "성범죄 전담 재판부 판사 출신 대표 변호사 직접 상담",
                    txt: "성범죄 전담 재판부 판사 출신 대표 변호사가 직접 의뢰인과 상담을 하며 사건 진행에 조력합니다.",
                    icon: "/images/sub/firm/about/about-icon.svg",
                },
                {
                    title: "1:1 개인 맞춤형 highend legal service",
                    txt: "100% 똑같은 사건이란 없습니다. 판심은 의뢰인 개개인의 사건의 본질을 명확하게 파악하고 성향에 맞는 맞춤형 법률 서비스를 제공합니다.",
                    icon: "/images/sub/firm/about/about-icon2.svg",
                },
                {
                    title: "Wait for 7 minutes",
                    txt: "초기 대응이 중요한 성범죄. 판심은 사건 발생 직후 7분 이내 변호사와 연결하여 대응방법을 알려드립니다.",
                    icon: "/images/sub/firm/about/about-icon3.svg",
                },
                {
                    title: "성범죄 재판을 직접 진두지휘 해 본 판사출신 변호사",
                    txt: "성범죄 사건에서 풍부한 경험과 실력을 지닌 성범죄 전담재판부 판사 출신 대표 변호사가 축적된 노하우를 활용하여 의뢰인의 사건 해결에 물적 및 인적 대응을 도와드리고 있습니다.",
                    icon: "/images/sub/firm/about/about-icon4.svg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section{
    &--about{
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        &__bg{
            position: absolute;
            bottom: var(--contents-padding-bottom);
            left: 0;
            width: 36%;
            z-index: -1;
            display: none;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .page-section{
        &--about{
            &__bg{
                display: block;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
