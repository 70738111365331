<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="사건별 맞춤 서비스">
                <template #titHead>S</template>
                <template #tit>ervice</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <div class="image-card pa-20px pa-md-30px px-lg-0 py-lg-70px" style="background-image: url(/images/sub/service/service-bg.jpg)">
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <div class="pl-lg-48px">
                            <u-tit-default data-aos="fade-up" class="line-height-17">
                                판심만의 사건별 맞춤서비스
                            </u-tit-default>
                            <u-txt-default data-aos="fade-up" data-aos-delay="200" class="line-height-17 mt-16px mt-md-32px">
                                판심 법무법인은 <br class="d-none d-lg-block">
                                대표변호사, 검사출신변호사, 각 분야 전문변호사가 의뢰인을 위한 <br class="d-none d-lg-block">
                                사건별 최적의 맞춤 법률서비스를 제공합니다.
                            </u-txt-default>
                        </div>
                    </v-col>
                    <v-col cols="6" offset="6" offset-md="0">
                        <div class="w-100 pr-lg-40px">
                            <v-img data-aos="zoom-out" src="/images/sub/service/service-bg2.svg" max-width="516" :aspect-ratio="516 / 358" class="w-100 ml-md-auto" />
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div v-for="(item, index) in info" :key="index" class="pt-40px pt-md-60px pt-lg-100px">
                <v-row align="center" class="image-row" :class="index % 2 == 0 ? 'image-row--reverse' : ''">
                    <v-col cols="12" md="6">
                        <div class="image-row__image">
                            <div class="image-row__image__inner">
                                <div data-aos="zoom-out" class="image-row__image__bg"></div>
                                <v-img data-aos="zoom-out" data-aos-delay="200" :src="item.image" :aspect-ratio="1 / 1" class="w-100"/>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="image-row__txt">
                            <u-tit-default data-aos="fade-up" class="tit--sm font-weight-semibold line-height-1 primary--text mb-8px mb-md-16px">
                                0{{index+1}}
                            </u-tit-default>
                            <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm">
                                {{item.title}}
                            </u-tit-default>
                            <u-txt-default class="mt-30px mt-md-60px line-height-17">
                                <p data-aos="fade-up" data-aos-delay="200" v-for="detail in item.txt" :key="detail" v-html="detail"></p>
                            </u-txt-default>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    data() {
        return {
            info: [
                {
                    title: "수사단계조력",
                    txt: [
                        "성범죄의 경우 다른 사건들보다 초기대응이 더욱 중요합니다. <br class='d-none d-lg-block' /> 판심 법무법인은 수임 직후 TF팀을 구성하여 사건파악을 한 후 최적의 사건대응에 착수합니다.",
                        "사건 유형별 판심 법무법인만의 노하우가 담긴 변호인의견서, 증거수집, 담당 수사관과의 의사소통, 수사입회 전 진술정리 및 입회 시 진술조력 등으로 최고의 변론으로 억울한 일을 당하지 않게 도와드립니다.",
                        "단, 재범의 위험성이 현저히 낮은 경우, 그 밖의 취업을 제한하여서는 아니 되는 특별한 사정이 있다고 판단하는 경우에는 취업제한명령을 선고하지 않을 수 있으므로 반드시 변호사의 조력을 받는 것이 필요합니다.",
                    ],
                    image: "/images/sub/service/service-img.jpg",
                },
                {
                    title: "체포/구속단계 조력",
                    txt: [
                        "성범죄 사건의 경우 예상치 못한 범죄로 체포/구속을 당하여 크게 당황할 수 있습니다. 체포나 구속을 당한 경우 그 즉시 변호인을 선임하여 구체적인 조력을 받는 것이 필요합니다.",
                        "체포/구속단계에선 신속한 대응이 생명이기 때문에 판심 법무법인은 24시간 대응팀을 구성하여 항시 대기하고 있습니다. 한걸음 빠른 대응으로 체포/구속단계에서 당신만을 위한 변론을 경험하실 수 있습니다.",
                    ],
                    image: "/images/sub/service/service-img2.jpg",
                },
                {
                    title: "재판단계조력",
                    txt: [
                        "판심 법무법인은 의뢰인을 끝까지 신뢰합니다. <br class='d-none d-lg-block' /> 의뢰인이 무죄를 주장한다면 판심 법무법인은 끝까지 의뢰인의 입장을 대변하여 최선의 노력을 동원하여 적극 무죄변론을 해드립니다.",
                        "또한 판심 법무법인은 형벌과는 별개로 취업제한, 전자발찌부착명령 등 보안처분의 필요성에 대한 변론까지 적극변론하여 의뢰인을 보호합니다.",
                        "사건 유형별 판심 법무법인만의 노하우가 담긴 변호인의견서, 증인신문, 법정변론 등 당신만을 위한 능동적이고 적극적인 변론으로 최선의 판결을 받을 수 있을 것입니다.",
                    ],
                    image: "/images/sub/service/service-img3.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.image-row{
    --image-padding: 20px;
    &__image{
        &__inner{
            max-width: 588px;
            position: relative;
            padding: 0 0 var(--image-padding) var(--image-padding);
        }
        &__bg{
            position: absolute;
            width: calc(100% - var(--image-padding));
            height: calc(100% - var(--image-padding));
            left: 0;
            bottom: 0;
            background-color: var(--v-primary-lighten5);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .image-row{
        --image-padding: 48px;
        &__image{
            padding-right: 30px;
        }
        &__txt{
            padding-left: calc(var(--image-padding) + 6px) !important;
            padding-right: var(--image-padding);
        }
        &--reverse{
            flex-direction: row-reverse;
            .image-row__txt{
                padding-left: var(--image-padding) !important;
                padding-right: calc(var(--image-padding) + 6px) !important;
            }
            .image-row__image{
                padding-right: 0;
                padding-left: 30px;
                &__inner{
                    padding: var(--image-padding) var(--image-padding) 0 0;
                }
                &__bg{
                    left: unset;
                    bottom: unset;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
